import React, { useState } from "react";
import { Button } from "../../ButtonElement";
import TestimonialSlider from "../Testimonials"

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Heading3,
  Subtitle,
  BtnWrap,
  Img,
  ImgWrap,
} from "./InfoElements";

const InfoSection = ({
  showTestimonial,
  isFilterActive,
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline1,
  headline2,
  headline3,
  darkText,
  description1,
  description2,
  description3,
  description4,
  buttonLabel,
  image,
  backgroundImage,
  showButton,
  buttonToPDF,
  buttonLink,
  alt,
  primary,
  dark,
  dark2,
  showTestimonialButton,
}) => {
    const [Link, setLink] = useState(buttonLink);

    const handleButtonClick = (e) => {
      if (buttonToPDF) {
        e.preventDefault();
        window.open(buttonLink, '_blank', 'noopener,noreferrer');
      }
    };

  return (
    <>
      <InfoContainer backgroundImage={backgroundImage} isFilterActive={isFilterActive} lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline1}</Heading>
                <Subtitle darkText={darkText}>{description1}</Subtitle>
                <Heading lightText={lightText}>{headline2}</Heading>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                <Heading lightText={lightText}>{headline3}</Heading>
                <Subtitle darkText={darkText}>{description3}</Subtitle>
                <Subtitle darkText={darkText}>{description4}</Subtitle>
                {showButton && <BtnWrap>
                  <Button
                    onClick={handleButtonClick}
                    to={Link}
                    smooth="true"
                    duration={500}
                    spy="true"
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>}
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
              <Img src={image} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
