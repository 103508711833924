import Drone from "../../images/drone.jpg"
import N8File from "../../files/NEURO8.pdf"
import FFace from "../../images/FemaleFace.webp"

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "WHY",
  headline1: "TRAINING",
  description1: (<>F I T - Focus Improvement Techniques<br /> 
                  A ‘proven’ neuroscience based programme that reduces risk and improves performance </>),
  headline2: "TECHNOLOGY",
  description2: (<>Biometrics - Smart wearables (HRV) <br /> 
                  Fake News - Sentiment analysis (SaaS)</>),
  headline3: "TALKS",
  description3: (<>People // Process // Technology <br /> 
                  ‘Fly a drone by thought alone’</>),
  buttonLabel: "Learn More",
  imgStart: true,
  image: FFace,
  alt: "AI generated face of a lady with the right side as a AI robot",
  dark: true,
  primary: true,
  darkText: false,
  showButton: true,
  buttonToPDF: true,
  buttonLink: N8File,
  showTestimonialButton: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "WHAT",
  headline1: "“Fly A Drone By Thought Alone”",
  description1: "Here is an example of one of our ‘special projects’ in the Defence and Security sector: UltraNIMBUS enables the operator to ‘fly a drone by thought alone’. We showcased this at the UK Specialist Defence & Security Conference (SDSC-UK) in 2021 and were featured in Soldier magazine and various media channels including Bloomberg. The capability moved from TRL 4 - TRL 6 during a defence (defense) sector trial with a US military client and Ultra PCS Ltd Q3 2023.",
  description2: "If we can fly a drone by thought alone imagine what we can achieve with your people?",
  description3: "'Humans are more important than hardware' USSOCOM Special Forces Command Truth #1",
  buttonLabel: "Learn More - NIMBUS",
  imgStart: false,
  image: Drone,
  // OLD STYLE image: require("../images/ppt.svg").default,
  // backgroundImage: backgroundImage,
  alt: "ellen with headset on",
  dark: false,
  primary: false,
  darkText: true,
  showButton: true,
  buttonToPDF: false,
  buttonLink: "tech",
  showTestimonialButton: false,
};

