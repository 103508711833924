import React from "react";
import styled from "styled-components";
import image from "../../images/FakeNewsScan.png"
import Features from "../../images/FNFeatures.png";
import Benefits from "../../images/FNBenefits.png";
import Example from "../../images/FNExample.png";
import icons03 from "../../images/Icons03.png";

const Background= styled.div`
background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
  
`;

const Container = styled.div`
  display: flex;
  align-items: absolute;
  padding: 20px;
  padding-bottom: 90px;
  text-align: center;

  @media screen and (max-width: 858px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  max-width: 40%;
  padding: 50px;

  @media screen and (max-width: 858px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 80%;
  height: auto;
  padding-top: 90px;

  @media screen and (max-width: 858px) {
    width: 80%;
    height: auto;
    padding-top: 0px;
  }
`;

const IconImage = styled.img`
width: 50%;
height: auto;
padding-top: 90px;

@media screen and (max-width: 858px) {
  width: 80%;
  height: auto;
  padding-top: 0px;
}
`;

const Title = styled.h1`
  font-size: 24px;
  margin-top: 40px;
`;

const Text = styled.p`
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  text-align: justify; 
`;

const TrainingSectionContainer = styled.div`
  background-color: #221F3C;  
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 22vh;
  padding-bottom: 22vh;

`;

const TrainingsContainer = styled.div`
  display: grid;
  width: auto;
  max-width: 90%;
  gap: 70px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */

  @media screen and (max-width: 1050px) {
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr; 
    gap: 40px;
  }
`;


const ImageRowContainer = styled.div`
// padding-top: 22vh;
padding-bottom: 12vh;
display: flex;
flex-wrap: wrap;
justify-content: space-around;

@media screen and (max-width: 920px) {
  justify-content: center;
  padding-top: 12vh;
  padding-bottom: 12vh;
}
`;

const TrainingContainer = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  // width: 50%;
  max-width: 600px;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TrainingHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
`;

const TrainingTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #0096FF;
`;


const TrainingsData = [
  {
    name: "Features",
    image: Features,
  },
  {
    name: "Benefits",
    image: Benefits,
  },
  {
    name: "Client Example",
    image: Example,
  },
];


const FakeNewsSection = () => {
  return (
    <Background>
    <Container>
        <Content>
          <Title>Who We Are</Title>
          <Text>
          Lead by former information warfare and cyber security experts, our strategic partners offer an AI-driven open-source intelligence platform to detect fake accounts that are spreading disinformation and fake news. They break down billions of online conversations in real time to provide a deeper understanding of what's hidden behind social media conversations.
          </Text>
          <Title>What We Do</Title>
          <Text>
          We SPOT market opportunities as well as fake news. By scanning social media channels we can spot opportunities to help grow your brand in markets, languages and locations you have not yet identified. The data helps drive your decisions.
          </Text>
        </Content>
        <ImageContainer>
        <Image
          src={image}
          alt="Scan Image"
        /></ImageContainer>
      </Container>
      <TrainingSectionContainer>
            <TrainingsContainer>
            {TrainingsData.map((project, index) => (
              <TrainingContainer key={index}>
                <TrainingHeader>{project.name}</TrainingHeader>
                  <TrainingTitle>{project.subtitle}</TrainingTitle>
                  {project.image && (
                    <img
                      src={project.image}
                      alt={`${project.name} Image`}
                      style={{
                        width: '100%',
                        height: 'auto',
                        marginTop: '10px',
                        paddingTop: '10px',
                      }}
                    />
                  )}
              </TrainingContainer>
            ))}
          </TrainingsContainer>
      </TrainingSectionContainer>      
    <ImageRowContainer>
        <IconImage src={icons03} alt="Social Media Icons" />
      </ImageRowContainer>
    </Background>
  );
};

export default FakeNewsSection;
