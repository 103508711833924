import React from "react";
import styled from "styled-components";
import LogoGroupScroll from "../../Home Components/LogoGroupScroll";
import Hereford from "../../images/trainingHereford.jpg";
import NCSLogo from "../../images/NCSLogo.png";
import NIH from "../../images/NIH.png"
import B from "../../images/waves/BETA.png";
import A from "../../images/waves/ALPH.png";
import T from "../../images/waves/THETA.png";

import {
  Background,
  Container,
  Content,
  Video,
  Title,
  Text,
  Text2,
  ExternalLink,
  TrainingSectionContainer,
  TrainingsContainer,
  TrainingContainer,
  TrainingHeader,
  TrainingTitle,
  TrainingText,
  BulletList,
  BulletListItem,
  ImageRowContainer,
  Image,

} from "./TrainingSectionElements"

const TrainingsData = [
  {
    name: "OPEN",
    image: B,
    subtitle: "‘Change Your Mind Create New Results’",
    charity:"10% Charity Donation",
    text2:(<>Audience:<br/>Anyone interested in Dr Joe Dispenza's corporate training programme</>),
    bullets: [      
      "1 days training",
      "30 day action plan",
      "2 x workbooks",
      "£795 p/person",
      ],
  },
  {
    name: "TEAMS",
    image: A,
    subtitle: "Corporate // Teams",
    charity: "10% Charity Donation",
    text2:(<>Audience: <br />Leadership, Management, High Performance Sports, Entrepeneurs, Corporate, Media, Public Sector, Defence / Defense</>),
    bullets: [
      "1 days training",
      "30 day action plan",
      "2 x workbooks",
      "Group coaching",
      "DASS21 Reporting",
      "£ bespoke",
    ],
  },
  {
    name: "RETREATS",
    image: T,
    subtitle: "Coming 2025",
    charity: "Charity 10% : Veteran or Client Choice",
    text2:(<>Audience:<br/>- Corporate leadership<br/>- Research trials (sponsored)<br/>- Veterans (sponsored)</>),
    bullets: [
      "1 days training",
      "30 day action plan",
      "2 x workbooks",
      "Group coaching",
      "DASS21 Reporting",
      "Ice bath",
      "Sauna",
      "Yoga",
      "Muay Thai",
      "12 week year plan",
      "Meditation",
      "£ bespoke",
    ],
  },
];

const TrainingSection = () => {
  return (
    <>
      <LogoGroupScroll />
      <Background>
      <Container>
        <Video
          src="https://www.youtube.com/embed/0WdNQcDIBYE?si=CbdVkOyXXVV3OE4e"
          frameBorder="0"
          allowFullScreen
        ></Video>
        <Content>
          <Title>Training Overview</Title>
          <Text>
          We use a combination of training and technology to measure “change” both personally and professionally. The foundation of our three training solutions is based on the latest research in neuroscience and utilises a course that helps individuals and teams “change” from the inside out. Here is a short video explaining how it works.
          </Text>
          <Text>
          Our founder, is part of a global network of corporate consultants trained by Dr. Joe Dispenza, New York Times best-selling author, lecturer, and researcher. As a certified NeuroChangeSolutions consultant, Ellen educates businesses on the CYM CNR programme. It teaches organisations how to apply the neuroscience of change to increase employee engagement, collaboration, creativity, productivity, and ultimately business results.
          </Text>
          <Text>
          NCS consultants have worked with teams at Cisco, Sony, Coca-Cola, IBM, Johnson & Johnson, USAID, Rackspace, Microsoft, Pfizer, Heineken, and many more.
          </Text>
            <Text>
            Below you can also see a copy of research from the National Library of Medicine, co-written by Dr Dispenza entitled 'Meditation-induced bloodborne factors as an adjuvant treatment’
              <ExternalLink
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10432704/"
              target="_blank"
            >
              ncbi.nlm.nih.gov
            </ExternalLink>
          </Text>
        </Content>
      </Container>
      <TrainingSectionContainer>
            <TrainingsContainer>
            {TrainingsData.map((project, index) => (
              <TrainingContainer key={index}>
                <TrainingHeader>{project.name}</TrainingHeader>
                  <TrainingTitle>{project.subtitle}</TrainingTitle>
                  {project.image && (
                    <img
                      src={project.image}
                      alt={`${project.name} Image`}
                      style={{
                        width: '100%',
                        height: 'auto',
                        marginTop: '10px',
                        paddingTop: '10px',
                        borderRadius: '40px',
                      }}
                    />
                  )}
                  <BulletList>
                  <ul>
                    {project.bullets.map((bullet, bulletIndex) => (
                      <BulletListItem key={bulletIndex}>{bullet}</BulletListItem>
                    ))}
                  </ul>
                <Text2>{project.text2}</Text2>
                <Text2>{project.text3}</Text2>
                <Text2>{project.charity}</Text2>
                </BulletList>
              </TrainingContainer>
            ))}
          </TrainingsContainer>
      </TrainingSectionContainer>
      <ImageRowContainer>
        <Image src={Hereford} alt="People sat at table in training room" />
        <Image src={NCSLogo} alt="NCSLogo" />
        <Image src={NIH} alt="National Institutes of Health Logo" />
      </ImageRowContainer>
      </Background>
    </>
  );
};

export default TrainingSection;
